import { website } from '@/axios'
export const citiesLink = 'admin/cities'

export default {
  getDetails (id) {
    return website().get(`${citiesLink}/${id}`)
  },
  add (payload) {
    return website().post(citiesLink, payload)
  },
  update (id, payload) {
    return website().patch(`${citiesLink}/${id}`, payload)
  },
  getAll () {
    return website().get(`${citiesLink}`)
  }
}
